.ck-editor__editable_inline {
  min-height: 500px;
  max-height: 50vh;
  min-width: 100%;
}

.page {
  background-color: #DAE0E6;
  min-height: 80vh;
}

.btn-primary {
    background-color: #FF4500 !important;
    border-color: #FF4500 !important;
}

.btn-primary:hover {
  background-color: #E53900 !important;
  border-color: #E53900 !important;
}

.btn-outline-primary {
  /* background-color: #000 !important; */
  border-color: #FF4500 !important;
  color: black !important;
}

.btn-outline-primary:hover {
  background-color: #E53900 !important;
  border-color: #E53900 !important;
  color: white !important;
}

@media screen and (max-width: 600px) {
  .arrow {
    display:none !important;
  }
}

@media screen and (max-width: 600px) {
  .hide-display{
    display:none !important;
  }
}

@media only screen and (min-width: 600px) {
  .unhide-display {
    display: none;
  }
}

.arrow {
  max-height: 250px;
  max-width: 450px;
}

.steps {
  height: auto;
  max-width: 100%;
}

.custom-background {
  background: linear-gradient(90deg, #DAE0E6 50%, white 50%);
}

